<template>
  <vs-sidebar
    v-model="isSidebarActiveLocal"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} USER
      </h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isSidebarActiveLocal = false"
      />
    </div>
    <vs-divider class="mb-0"/>

    <VuePerfectScrollbar
      :settings="settings"
      :key="$vs.rtl"
      class="scroll-area--data-list-add-new"
    >
      <div class="p-6">
        <vs-input
          v-validate="'required|alpha'"
          v-model="username"
          label="Username"
          class="mt-5 w-full"
          name="username"
        />
        <span 
          v-show="errors.has('username')" 
          class="text-danger text-sm">{{
            errors.first("username")
          }}</span>

        <vs-input
          v-validate="'required'"
          v-model="password"
          label="Password"
          class="mt-5 mb-2 w-full"
          name="password"
        />
        <span 
          v-show="errors.has('password')" 
          class="text-danger text-sm">{{
            errors.first("password")
          }}</span>

        <vs-divider>Other</vs-divider>

        <vs-input
          v-validate="'required|min:4'"
          v-model="name"
          label="Name"
          class="mt-3 w-full"
          name="name"
        />
        <span 
          v-show="errors.has('name')" 
          class="text-danger text-sm">{{
            errors.first("name")
          }}</span>

        <vs-input
          v-validate="'email'"
          v-model="email"
          label="Email"
          class="mt-3 w-full"
          name="email"
        />
        <span 
          v-show="errors.has('email')" 
          class="text-danger text-sm">{{
            errors.first("email")
          }}</span>

        <vs-input
          v-validate="'required|numeric|max:10|min:10'"
          v-model="phone"
          label="Phone"
          class="mt-5 w-full"
          name="phone"
        />
        <span 
          v-show="errors.has('phone')" 
          class="text-danger text-sm">{{
            errors.first("phone")
          }}</span>
        <br >
        <small class="mt-5">Role</small>
        <v-select
          v-validate="'required'"
          v-model="role"
          :options="roles"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          class="inputx w-full"
          name="role"
        />
        <span 
          v-show="errors.has('role')" 
          class="text-danger text-sm">{{
            errors.first("role")
          }}</span>

        <br >
        <small 
          v-show="role != null && role.role != 'admin'" 
          class="mt-5"
        >Branch</small
        >
        <v-select
          v-validate="'required'"
          v-if="role != null && role.role != 'admin'"
          v-model="branch"
          :options="branchOptions"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          class="inputx w-full"
          name="branch"
          label="branch_name"
        />
        <span 
          v-show="errors.has('branch')" 
          class="text-danger text-sm">{{
            errors.first("branch")
          }}</span>
      </div>
    </VuePerfectScrollbar>

    <div 
      slot="footer" 
      class="flex flex-wrap items-center p-6">
      <vs-button 
        :disabled="!isFormValid" 
        class="mr-6" 
        @click="submitData"
      >Submit</vs-button
      >
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
      >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";

export default {
  components: {
    VuePerfectScrollbar,
    "v-select": vSelect,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: null,
      username: "",
      password: "",
      name: "",
      email: "",
      phone: "",
      branch: null,
      role: null,
      roles: [
        {
          role: "cashier",
          label: "Cashier",
        },
        {
          role: "admin",
          label: "Admin",
        },
      ],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    branchOptions() {
      return this.branchData ? this.branchData : [];
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.username &&
        this.password &&
        this.name &&
        this.email &&
        this.phone &&
        this.role 
      );
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let {
          _id,
          username,
          password,
          name,
          email,
          phone,
          role,
          branch,
        } = JSON.parse(JSON.stringify(this.data));
        this.id = _id;
        this.username = username;
        this.password = password;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.branch = branch;
        this.role = role;
        this.initValues();
      }
    },
  },
  pouch: {
    branchData() {
      return {
        database: "express",
        selector: { type: "branch" },
        sort: [
          {
            name: "asc",
          },
        ],
      };
    },
  },
  methods: {
    initValues() {
      if (this.data._id) return;
      this.id = null;
      this.username = "";
      this.password = "";
      this.name = "";
      this.email = "";
      this.phone = "";
      this.branch = null;
      this.role = null;
    },
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.notify({
            color: "warning",
            title: "Processing",
            text: "Processing Data",
          });
          const obj = {
            username: this.username,
            password: this.password,
            name: this.name,
            email: this.email,
            phone: this.phone,
            role: this.role.role,
            branch: this.branch ? this.branch._id : null,
          };
          this.$store
            .dispatch("users/addUser", obj)
            .then(() => {
              this.$vs.notify({
                color: "success",
                title: "User Registered Successfully",
                text: "You have successfully registered a user",
              });
              this.$emit("closeSidebar");
              this.initValues();
            })
            .catch((err) => {
              console.error(err);
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: err,
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
